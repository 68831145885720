<nav class="navbar navbar-dark bg-primary fixed-top">
  <a class="navbar-brand" routerLink="/">
    <img alt="logo" src="/assets/images/logo_mini.gif" style="width:33px; height:33px"> &nbsp; {{title}}
  </a>
  <div class="navbar-expand mr-auto">
    <div class="navbar-nav">
      <a class="nav-item nav-link" routerLink="architektur">Softwarearchitektur</a>
      <a class="nav-item nav-link" routerLink="datenschutz">Datenschutz-Beratung</a>
      <a class="nav-item nav-link" routerLink="itsicherheit">IT-Sicherheit</a>
    </div>
  </div>
  <div class="navbar-expand ml-auto">
    <div class="navbar-nav nav-justified">
      <a class="nav-item nav-link" routerLink="kontakt">Kontakt</a>
      <a class="nav-item nav-link" routerLink="impressum">Impressum</a>
      <a class="nav-item nav-link" routerLink="dataprotect">Datenschutz</a>
    </div>
  </div>
</nav>