<ol class="breadcrumb">
  <li class="breadcrumb-item"><a href="#">Home</a></li>
  <li class="breadcrumb-item active">Architektur</li>
</ol>
<div class="container">
  <h1><i class="bi-diagram-3"></i>&nbsp; Softwarearchitektur<small>@itc</small></h1>
</div>
<div class="alert alert-warning">
  <h4 class="alert-heading">Warning!</h4>
  <p class="mb-0">
  This page is under construction!
  </p>
</div>
  