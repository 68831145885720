<ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active">Datenschutzerkl&auml;rung</li>
</ol>
<div class="container" style="text-align: left;">
    <h1><i class="bi bi-exclamation-square"></i>&nbsp;Datenschutzerkl&auml;rung<small>@itc</small></h1>

    <h2>I. Name und Anschrift des Verantwortlichen</h2>
    <p>Der Verantwortliche im Sinne der
    Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der
    Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:</p>
    
    <ul>
        <li><i>IT-Consulting Will</i></li>
        <li><i>St&ouml;pfertal 49</i></li>
        <li><i>98544 Zella-Mehlis</i></li>
        <li><i>Deutschland</i></li>
        <li><i>Tel.: +49 36843 60140</i></li>
        <li><i>E-Mail: info[at]itconsulting-will.de</i></li>
        <li><i>Website: www.itconsulting-will.de</i></li>
    </ul>
    <h2>II. Name und Anschrift des Datenschutzbeauftragten</h2>
    <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
    
    <ul>
        <li><i>Andreas Will</i></li>
        <li><i>St&ouml;pfertal 49</i></li>
        <li><i>98544 Zella-Mehlis</i></li>
        <li><i>Deutschland</i></li>
        <li><i>Tel.: +49 36843 60140</i></li>
        <li><i>E-Mail: datenschutz[at]itconsulting-will.de</i></li>
        <li><i>Website: www.itconsulting-will.de</i></li>    
    </ul>
    
    <h2>III. Allgemeines zur Datenverarbeitung</h2>
    <h3>1. Umfang der Verarbeitung personenbezogener Daten</h3>
    <p>Wir verarbeiten personenbezogene Daten unserer
    Nutzer grunds&auml;tzlich nur, soweit dies zur Bereitstellung einer funktionsf&auml;higen
    Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung
    personenbezogener Daten unserer Nutzer erfolgt regelm&auml;&szlig;ig nur nach Einwilligung
    des Nutzers. Eine Ausnahme gilt in solchen F&auml;llen, in denenueine vorherige
    Einholung einer Einwilligung aus tats&auml;chlichen Gr&auml;nden nicht m&ouml;glich ist und
    die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist. </p>
    
    <h3>2. Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten</h3>
    <p>Soweit wir f&uuml;r Verarbeitungsvorg&auml;nge
    personenbezogener Daten eine Einwilligung der betroffenen Person einholen,
    dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a EU-Datenschutzgrundverordnung (DSGVO)
    als Rechtsgrundlage.</p>
    
    <p>Bei der Verarbeitung von personenbezogenen Daten,
    die zur Erf&uuml;llung eines Vertrages, dessen Vertragspartei die betroffene Person
    ist, erforderlich ist, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO als
    Rechtsgrundlage. Dies gilt auch f&uuml;r Verarbeitungsvorg&auml;nge, die zur Durchf&uuml;hrung
    vorvertraglicher Ma&szlig;nahmen erforderlich sind.</p>
    
    <p>Soweit eine Verarbeitung personenbezogener Daten
    zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich ist, der unser
    Unternehmen unterliegt, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;c DSGVO als
    Rechtsgrundlage.</p>
    
    <p>F&uuml;r den Fall, dass lebenswichtige Interessen der
    betroffenen Person oder einer anderen nat&uuml;rlichen Person eine Verarbeitung
    personenbezogener Daten erforderlich machen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;d
    DSGVO als Rechtsgrundlage.</p>
    
    <p>Ist die Verarbeitung zur Wahrung eines berechtigten
    Interesses unseres Unternehmens oder eines Dritten erforderlich und &uuml;berwiegen
    die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
    erstgenannte Interesse nicht, so dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f
    DSGVO als Rechtsgrundlage f&uuml;r die Verarbeitung. </p>
    
    <h3>3. Datenl&ouml;schung und Speicherdauer</h3>
    
    <p>Die personenbezogenen Daten der betroffenen Person werden
    gel&ouml;scht oder gesperrt, sobald der Zweck der Speicherung entf&auml;llt. Eine
    Speicherung kann dar&uuml;ber hinaus erfolgen, wenn dies durch den europ&auml;ischen oder
    nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
    sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.
    Eine Sperrung oder L&ouml;schung der Daten erfolgt auch dann, wenn eine durch die
    genannten Normen vorgeschriebene Speicherfrist abl&auml;uft, es sei denn, dass eine
    Erforderlichkeit zur weiteren Speicherung der Daten f&uuml;r einen Vertragsabschluss
    oder eine Vertragserf&uuml;llung besteht.</p>
    
    <h2>IV. Bereitstellung der Website und Erstellung von Logfiles</h2>
    <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
    
    <p>Bei jedem Aufruf unserer Internetseite erfasst
    unser System automatisiert Daten und Informationen vom Computersystem des
    aufrufenden Rechners. </p>
    
    <p>Folgende Daten werden hierbei erhoben:</p>
    
    <p>(1) Informationen &uuml;ber den Browsertyp und die verwendete Version</p>
    <p>(2) Das Betriebssystem des Nutzers</p>
    <p>(3) Den Internet-Service-Provider des Nutzers</p>
    <p>(4) Die IP-Adresse des Nutzers</p>
    <p>(5) Datum und Uhrzeit des Zugriffs</p>
    <p>(6) Websites, von denen das System des Nutzers auf unsere Internetseite gelangt </p>
    <p>(7) Websites, die vom System des Nutzers &uuml;ber unsere Website aufgerufen werden</p>
    
    <p>Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung
    dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet
    nicht statt.</p>
    
    <h3>2. Rechtsgrundlage f&uuml;r die Datenverarbeitung </h3>
    <p>Rechtsgrundlage f&uuml;r die vor&uuml;bergehende Speicherung der Daten und der Logfiles ist Art.&nbsp;6 Abs.&nbsp;1
    lit.&nbsp;f DSGVO.</p>
    
    <h3>3. Zweck der Datenverarbeitung</h3>
    
    <p>Die vor&uuml;bergehende Speicherung der IP-Adresse durch
    das System ist notwendig, um eine Auslieferung der Website an den Rechner des
    Nutzers zu erm&ouml;glichen. Hierf&uuml;r muss die IP-Adresse des Nutzers f&uuml;r die Dauer
    der Sitzung gespeichert bleiben. </p>
        
    <p>Die Speicherung in Logfiles erfolgt, um die Funktionsf&auml;higkeit der Website
    sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur
    Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine
    Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
    statt. </p>
    
    <p>In diesen Zwecken liegt auch unser berechtigtes
    Interesse an der Datenverarbeitung nach Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f
    DSGVO.</p>
    
    <h3>4. Dauer der Speicherung</h3>
    
    <p>Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die
    Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle
    der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn
    die jeweilige Sitzung beendet ist. </p>
    
    <p>Im Falle der Speicherung
    der Daten in Logfiles ist dies nach sp&auml;testens neun Wochen der Fall. Eine
    dar&uuml;berhinausgehende Speicherung ist ausgeschlossen.</p>
    
    <h3>5. Widerspruchs- und Beseitigungsm&ouml;glichkeit</h3>
    
    <p>Die Erfassung der Daten zur Bereitstellung der Website und
    die Speicherung der Daten in Logfiles ist f&uuml;r den Betrieb der Internetseite
    zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
    Widerspruchsm&ouml;glichkeit. </p>
    
    <h2>V. Kontaktformular und E-Mail-Kontakt</h2>
    
    <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
    
    
    <p>Eine Kontaktaufnahme ist &uuml;ber die
    bereitgestellte E-Mail-Adresse m&ouml;glich. In diesem Fall werden die mit der
    E-Mail &uuml;bermittelten personenbezogenen Daten des Nutzers gespeichert. </p>
    
    <p>Es erfolgt in diesem Zusammenhang keine Weitergabe
    der Daten an Dritte. Die Daten werden ausschlie&szlig;lich f&uuml;r die Verarbeitung der
    Konversation verwendet.</p>
    
    <h3>2.Rechtsgrundlage f&uuml;r die Datenverarbeitung </h3>
    
    <p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist
    bei Vorliegen einer Einwilligung des Nutzers Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a
    DSGVO.</p>
    
    <p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten, die
    im Zuge einer &Uuml;bersendung einer E-Mail &uuml;bermittelt werden, ist Art.&nbsp;6
    Abs.&nbsp;1 lit.&nbsp;f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines
    Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art.&nbsp;6
    Abs.&nbsp;1 lit.&nbsp;b DSGVO.</p>
    
    <h3>3. Zweck der Datenverarbeitung</h3>
    
    <p>Die Verarbeitung der personenbezogenen Daten aus
    der Eingabemaske dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle
    einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
    berechtigte Interesse an der Verarbeitung der Daten.</p>
    
    <p>Die sonstigen w&auml;hrend des Absendevorgangs
    verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch des
    Kontaktformulars zu verhindern und die Sicherheit unserer
    informationstechnischen Systeme sicherzustellen.</p>
    
    <h3>4. Dauer der Speicherung</h3>
    
    <p>Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die
    Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. f&uuml;r die
    personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und
    diejenigen, die per E-Mail &uuml;bersandt wurden, ist dies dann der Fall, wenn die
    jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation
    dann, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene
    Sachverhalt abschlie&szlig;end gekl&auml;rt ist. </p>
    
    <p>Die w&auml;hrend des Absendevorgangs zus&auml;tzlich erhobenen
    personenbezogenen Daten werden sp&auml;testens nach einer Frist von sieben Tagen
    gel&ouml;scht.</p>
    
    <h3>5. Widerspruchs- und Beseitigungsm&ouml;glichkeit</h3>
    
    <p>Der Nutzer hat jederzeit die M&ouml;glichkeit, seine
    Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt
    der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner
    personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die
    Konversation nicht fortgef&uuml;hrt werden.</p>
    
    <p>Alle personenbezogenen Daten, die im Zuge der
    Kontaktaufnahme gespeichert wurden, werden in diesem Fall gel&ouml;scht.</p>
    
    <h2>VI. Rechte der betroffenen Person</h2>
    
    <p><i>Die folgende Auflistung umfasst alle Rechte der
    Betroffenen nach der DSGVO. Rechte, die f&uuml;r die eigene Webseite keine Relevanz haben,
    m&uuml;ssen nicht genannt werden. Insoweit kann die Auflistung gek&uuml;rzt werden.</i></p>
    
    <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind
    Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegen&uuml;ber dem
    Verantwortlichen zu:</p>
    
    <h3>1. Auskunftsrecht</h3>
    
    <p>Sie k&ouml;nnen von dem Verantwortlichen eine Best&auml;tigung dar&uuml;ber
    verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet
    werden. </p>
    
    <p>Liegt eine solche Verarbeitung vor, k&ouml;nnen Sie von dem
    Verantwortlichen &uuml;ber folgende Informationen Auskunft verlangen:</p>
    
    <p>(1) die Zwecke, zu denen die personenbezogenen Daten
    verarbeitet werden;</p>
    
    <p>(2) die Kategorien von personenbezogenen Daten, welche
    verarbeitet werden;</p>
    
    <p>(3) die Empf&auml;nger bzw. die Kategorien von Empf&auml;ngern, gegen&uuml;ber denen die Sie betreffenden
    personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</p>
    
    <p>(4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten
    oder, falls konkrete Angaben hierzu nicht m&ouml;glich sind, Kriterien f&uuml;r die
    Festlegung der Speicherdauer;</p>
    
    <p>(5) das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der Sie betreffenden
    personenbezogenen Daten, eines Rechts auf Einschr&auml;nkung der Verarbeitung durch
    den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung; </p>
    
    <p>(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde;</p>
    
    <p>(7) alle verf&uuml;gbaren Informationen &uuml;ber die Herkunft der Daten, wenn die
    personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</p>
    
    <p>(8) das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling
    gem&auml;&szlig; Art.&nbsp;22 Abs.&nbsp;1 und 4 DSGVO und ( zumindest in diesen F&auml;llen )
    aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik sowie die Tragweite
    und die angestrebten Auswirkungen einer derartigen Verarbeitung f&uuml;r die
    betroffene Person.</p>
    
    <p>Ihnen steht das Recht zu, Auskunft dar&uuml;ber zu verlangen, ob
    die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine
    internationale Organisation &uuml;bermittelt werden. In diesem Zusammenhang k&ouml;nnen
    Sie verlangen, &uuml;ber die geeigneten Garantien gem. Art.&nbsp;46 DSGVO im
    Zusammenhang mit der &Uuml;bermittlung unterrichtet zu werden.</p>
    
    <h3>2. Recht auf Berichtigung </h3>
    
    <p>Sie haben ein Recht auf Berichtigung und/oder
    Vervollst&auml;ndigung gegen&uuml;ber dem Verantwortlichen, sofern die verarbeiteten
    personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollst&auml;ndig sind.
    Der Verantwortliche hat die Berichtigung unverz&uuml;glich vorzunehmen.</p>
    
    <h3>3. Recht auf Einschr&auml;nkung der Verarbeitung</h3>
    
    <p>Unter den folgenden Voraussetzungen k&ouml;nnen Sie die
    Einschr&auml;nkung der Verarbeitung der Sie betreffenden personenbezogenen Daten
    verlangen:</p>
    
    <p>(1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen f&uuml;r eine Dauer
    bestreiten, die es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit der
    personenbezogenen Daten zu &uuml;berpr&uuml;fen;</p>
    
    <p>(2) die Verarbeitung unrechtm&auml;&szlig;ig ist und Sie die L&ouml;schung der personenbezogenen Daten
    ablehnen und stattdessen die Einschr&auml;nkung der Nutzung der personenbezogenen
    Daten verlangen;</p>
    
    <p>(3) der Verantwortliche die personenbezogenen Daten f&uuml;r die Zwecke der Verarbeitung
    nicht l&auml;nger ben&ouml;tigt, Sie diese jedoch zur Geltendmachung, Aus&uuml;bung oder
    Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, oder</p>
    
    <p>(4) wenn Sie Widerspruch gegen die Verarbeitung gem&auml;&szlig; Art.&nbsp;21 Abs.&nbsp;1 DSGVO
    eingelegt haben und noch nicht feststeht, ob die berechtigten Gr&uuml;nde des
    Verantwortlichen gegen&uuml;ber Ihren Gr&uuml;nden &uuml;berwiegen.</p>
    
    <p>Wurde die Verarbeitung der Sie betreffenden
    personenbezogenen Daten eingeschr&auml;nkt, d&uuml;rfen diese Daten ( von ihrer
    Speicherung abgesehen ) nur mit Ihrer Einwilligung oder zur Geltendmachung,
    Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte
    einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
    wichtigen &ouml;ffentlichen Interesses der Union oder eines Mitgliedstaats
    verarbeitet werden.</p>
    
    <p>Wurde die Einschr&auml;nkung der Verarbeitung nach den o.g.
    Voraussetzungen eingeschr&auml;nkt, werden Sie von dem Verantwortlichen unterrichtet
    bevor die Einschr&auml;nkung aufgehoben wird.</p>
    
    <h3>4. Recht auf L&ouml;schung</h3>

    <h4>a) L&ouml;schungspflicht</h4>
    
    <p>Sie k&ouml;nnen von dem Verantwortlichen verlangen, dass die Sie
    betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht werden, und der
    Verantwortliche ist verpflichtet, diese Daten unverz&uuml;glich zu l&ouml;schen, sofern
    einer der folgenden Gr&uuml;nde zutrifft:</p>
    
    <p>(1) Die Sie betreffenden personenbezogenen Daten sind f&uuml;r die Zwecke, f&uuml;r die sie
    erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</p>
    
    <p>(2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art.&nbsp;6 Abs.&nbsp;1
    lit.&nbsp;a oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a DSGVO st&uuml;tzte, und es
    fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r die Verarbeitung. </p>
    
    <p>(3) Sie legen gem. Art.&nbsp;21 Abs.&nbsp;1 DSGVO Widerspruch gegen die Verarbeitung
    ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung
    vor, oder Sie legen gem. Art.&nbsp;21 Abs.&nbsp;2 DSGVO Widerspruch gegen die
    Verarbeitung ein. </p>
    
    <p>(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtm&auml;&szlig;ig verarbeitet. </p>
    
    <p>(5) Die L&ouml;schung der Sie betreffenden personenbezogenen Daten ist zur Erf&uuml;llung einer
    rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, 
    dem der Verantwortliche unterliegt. </p>
    
    <p>(6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste
    der Informationsgesellschaft gem&auml;&szlig; Art.&nbsp;8 Abs.&nbsp;1 DSGVO erhoben.</p>
    
    <h4>b) Information an Dritte</h4>
    
    <p>Hat der Verantwortliche die Sie betreffenden
    personenbezogenen Daten &ouml;ffentlich gemacht und ist er gem. Art.&nbsp;17 Abs.&nbsp;1
    DSGVO zu deren L&ouml;schung verpflichtet, so trifft er unter Ber&uuml;cksichtigung der
    verf&uuml;gbaren Technologie und der Implementierungskosten angemessene Ma&szlig;nahmen,
    auch technischer Art, um f&uuml;r die Datenverarbeitung Verantwortliche, die die
    personenbezogenen Daten verarbeiten, dar&uuml;ber zu informieren, dass Sie als
    betroffene Person von ihnen die L&ouml;schung aller Links zu diesen
    personenbezogenen Daten oder von Kopien oder Replikationen dieser
    personenbezogenen Daten verlangt haben. </p>
    
    <h4>c) Ausnahmen</h4>
    
    <p>Das Recht auf L&ouml;schung besteht nicht, soweit die
    Verarbeitung erforderlich ist</p>
    
    <p>(1) zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung
    und Information;</p>
    
    <p>(2) zur Erf&uuml;llung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
    der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt,
    erfordert, oder zur Wahrnehmung einer Aufgabe, die im &ouml;ffentlichen Interesse
    liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen
    &uuml;bertragen wurde;</p>
    
    <p>(3) aus Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich der &ouml;ffentlichen Gesundheit
    gem&auml;&szlig; Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;h und i sowie Art.&nbsp;9 Abs.&nbsp;3 DSGVO;</p>
    
    <p>(4) f&auml;r im &ouml;ffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
    historische Forschungszwecke oder f&uuml;r statistische Zwecke gem. Art.&nbsp;89 Abs.&nbsp;1
    DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die
    Verwirklichung der Ziele dieser Verarbeitung unm&ouml;glich macht oder ernsthaft
    beeintr&auml;chtigt, oder</p>
    
    <p>(5) zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>
    
    <h3>5. Recht auf Unterrichtung</h3>
    
    <p>Haben Sie das Recht auf Berichtigung, L&ouml;schung oder
    Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht,
    ist dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden
    personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung
    der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies
    erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand
    verbunden.</p>
    
    <p>Ihnen steht gegen&uuml;ber dem Verantwortlichen das Recht zu,
    &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</p>
    
    <h3>6. Recht auf Daten&uuml;bertragbarkeit</h3>
    
    <p>Sie haben das Recht, die Sie betreffenden personenbezogenen
    Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem
    strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Au&szlig;erdem
    haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung
    durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
    wurden, zu &uuml;bermitteln, sofern</p>
    
    <p>(1) die Verarbeitung auf einer Einwilligung gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a DSGVO
    oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a DSGVO oder auf einem Vertrag gem. Art.&nbsp;6
    Abs.&nbsp;1 lit.&nbsp;b DSGVO beruht und</p>
    
    <p>(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
    
    <p>In Aus&uuml;bung dieses Rechts haben Sie ferner das Recht, zu
    erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem
    Verantwortlichen einem anderen Verantwortlichen &uuml;bermittelt werden, soweit dies
    technisch machbar ist. Freiheiten und Rechte anderer Personen d&uuml;rfen hierdurch
    nicht beeintr&auml;chtigt werden.</p>
    
    <p>Das Recht auf Daten&uuml;bertragbarkeit gilt nicht f&uuml;r eine
    Verarbeitung personenbezogener Daten, die f&uuml;r die Wahrnehmung einer Aufgabe
    erforderlich ist, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung
    &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde.</p>
    
    <h3>7. Widerspruchsrecht</h3>
    
    <p>Sie haben das Recht, aus Gr&uuml;nden, die sich aus ihrer
    besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
    betreffenden personenbezogenen Daten, die aufgrund von Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;e
    oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese
    Bestimmungen gest&uuml;tztes Profiling. </p>
    
    <p>Der Verantwortliche verarbeitet die Sie betreffenden
    personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
    schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen,
    Rechte und Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der
    Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>
    
    <p>Werden die Sie betreffenden personenbezogenen Daten
    verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
    Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
    zum Zwecke derartiger Werbung einzulegen; dies gilt auch f&uuml;r das Profiling,
    soweit es mit solcher Direktwerbung in Verbindung steht.</p>
    
    <p>Widersprechen Sie der Verarbeitung f&uuml;r Zwecke der
    Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht
    mehr f&uuml;r diese Zwecke verarbeitet.</p>
    
    <p>Sie haben die M&ouml;glichkeit, im Zusammenhang mit der Nutzung
    von Diensten der Informationsgesellschaft () ungeachtet der Richtlinie
    2002/58/EG ) Ihr Widerspruchsrecht mittels automatisierter Verfahren auszu&uuml;ben,
    bei denen technische Spezifikationen verwendet werden.</p>
    
    <h3>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserkl&auml;rung</h3>
    
    <p>Sie haben das Recht, Ihre datenschutzrechtliche
    Einwilligungserkl&auml;rung jederzeit zu widerrufen. Durch den Widerruf der
    Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum
    Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt.</p>
    
    <h3>9. Automatisierte Entscheidung im Einzelfall einschlie&auml;lich Profiling</h3>
    
    <p>Sie haben das Recht, nicht einer ausschlie&szlig;lich auf einer
    automatisierten Verarbeitung ( einschlie&szlig;lich Profiling ) beruhenden
    Entscheidung unterworfen zu werden, die Ihnen gegen&uuml;ber rechtliche Wirkung
    entfaltet oder Sie in &auml;hnlicher Weise erheblich beeintr&auml;chtigt. Dies gilt
    nicht, wenn die Entscheidung </p>
    
    <p>(1) f&uuml;r den Abschluss oder die Erf&uuml;llung eines Vertrags zwischen Ihnen und dem
    Verantwortlichen erforderlich ist,</p>
    
    <p>(2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
    Verantwortliche unterliegt, zul&auml;ssig ist und diese Rechtsvorschriften
    angemessene Ma&szlig;nahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer
    berechtigten Interessen enthalten oder</p>
    
    <p>(3) mit Ihrer ausdr&uuml;cklichen Einwilligung erfolgt.</p>
    
    <p>Allerdings d&uuml;rfen diese Entscheidungen nicht auf besonderen
    Kategorien personenbezogener Daten nach Art.&nbsp;9 Abs.&nbsp;1 DSGVO beruhen,
    sofern nicht Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a oder g DSGVO gilt und
    angemessene Ma&szlig;nahmen zum Schutz der Rechte und Freiheiten sowie Ihrer
    berechtigten Interessen getroffen wurden.</p>
    
    <p>Hinsichtlich der in (1) und (3) genannten F&auml;lle trifft der
    Verantwortliche angemessene Ma&szlig;nahmen, um die Rechte und Freiheiten sowie Ihre
    berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des
    Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des
    eigenen Standpunkts und auf Anfechtung der Entscheidung geh&ouml;rt.</p>
    
    <h3>10. Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde</h3>
    
    <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
    gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde,
    insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes
    oder des Orts des mutma&szlig;lichen Versto&szlig;es, zu, wenn Sie der Ansicht sind, dass
    die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO
    verst&ouml;&szlig;t. </p>
    
    <p>Die Aufsichtsbeh&ouml;rde, bei der die Beschwerde eingereicht
    wurde, unterrichtet den Beschwerdef&uuml;hrer &uuml;ber den Stand und die Ergebnisse der
    Beschwerde einschlie&szlig;lich der M&ouml;glichkeit eines gerichtlichen Rechtsbehelfs
    nach Art.&nbsp;78 DSGVO.</p>
</div>