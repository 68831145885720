import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-itsicherheit',
  templateUrl: './itsicherheit.component.html',
  styleUrls: ['./itsicherheit.component.scss']
})
export class ItsicherheitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
