<div class="container" style="text-align:center">
  <div class="jumbotron">
    <h1>{{ title }}</h1>
    <h2>Ihr Partner für Individualsoftware</h2>
  </div>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
    <div class="col mx-auto">
      <div class="card text-white bg-primary mb-3">
        <div class="card-header">Planung und Entwurf</div>
        <div class="card-body">
          <h4 class="card-title">Softwarearchitektur</h4>
          <p class="card-text">Ich unterstütze Sie bei der Realisierung Ihres Softwareproduktes.</p>
          <nav><a routerLink="/architektur" class="card-link">mehr erfahren...</a></nav>
        </div>
      </div>
    </div>
    <div class="col mx-auto">
      <div class="card text-white bg-primary mb-3">
        <div class="card-header">Umsetzung und Prüfung</div>
        <div class="card-body">
          <h4 class="card-title">Datenschutz</h4>
          <p class="card-text">Ich berate Sie zu Fragen zur DSGVO und unterstütze Sie bei der Umsetzung.</p>
          <nav><a routerLink="/datenschutz" class="card-link">mehr erfahren...</a></nav>
        </div>
      </div>
    </div>
    <div class="col mx-auto">
      <div class="card text-white bg-primary mb-3">
        <div class="card-header">Beratung und Unterstützung</div>
        <div class="card-body">
          <h4 class="card-title">IT-Sicherheit</h4>
          <p class="card-text">Ich betreue Sie bei der Sicherheit Ihrer Software.</p>
          <nav><a routerLink="/itsicherheit" class="card-link">mehr erfahren...</a></nav>
        </div>
      </div>
    </div>
  </div>  
</div>
<router-outlet></router-outlet>