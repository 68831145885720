import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';

import { ArchitekturComponent } from './architektur/architektur.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ItsicherheitComponent } from './itsicherheit/itsicherheit.component'; 

import { KontaktComponent } from "./kontakt/kontakt.component";
import { DataprotectComponent } from './dataprotect/dataprotect.component';
import { ImpressumComponent } from './impressum/impressum.component';

const routes: Routes = [
{ path: '', redirectTo: '/home', pathMatch: 'full' },
{ path: 'home',  component: DashboardComponent,},
{ path: 'architektur',  component: ArchitekturComponent,},
{ path: 'datenschutz',  component: DatenschutzComponent,},
{ path: 'itsicherheit',  component: ItsicherheitComponent,},
{ path: 'kontakt',  component: KontaktComponent,},
{ path: 'dataprotect',  component: DataprotectComponent,},
{ path: 'impressum',  component: ImpressumComponent,}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
