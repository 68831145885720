<ol class="breadcrumb">
  <li class="breadcrumb-item"><a href="#">Home</a></li>
  <li class="breadcrumb-item active">Datenschutz</li>
</ol>
<div class="container">
  <h1><i class="bi-lock"></i>&nbsp; Datenschutz<small>@itc</small></h1>
<!--
  <ul>
    <li>Datenschutzrechtliche Beratung unseres Kundenstammes</li>
    <li>Durchf&uuml;hrung von Datenschutzpr&uuml;fung</li>
    <li>Erstellung von Beratungsberichten</li>
    <li>Besprechung von Jahresberichten mit der Gesch&auml;ftsf&uuml;hrung unserer Kunden</li>
    <li>Beantwortung datenschutzrechtlicher Fragen</li>
    <li>Erstellung von Betriebsvereinbarungen, Vereinbarung zur Auftragsverarbeitung, 
      &Uuml;bersichten von Verarbeitungst&auml;tigkeiten, uvm.</li>
    <li>F&uuml;hrung von Mitarbeiterschulungen</li>
    <li>Begleitung von Pr&uuml;fungen durch Beh&ouml;rden</li>
    <li>Pr&uuml;fung von Auftragsverarbeitern im Rahmen von Auftragsverarbeitungen</li>
  </ul>
  <ul>
    <li>Praxiserfahrung sowie regelm&auml;&szlig;ige Schulungen im Bereich Datenschutz</li>
    <li>Sehr gute Kenntnisse hinsichtlich der DSGVO und dem BDSG</li>
  </ul>
 
-->
</div>
<div class="alert alert-warning">
  <h4 class="alert-heading">Warning!</h4>
  <p class="mb-0">
  This page is under construction!
  </p>
</div>
