import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DataprotectComponent } from './dataprotect/dataprotect.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ArchitekturComponent } from './architektur/architektur.component';
import { ItsicherheitComponent } from './itsicherheit/itsicherheit.component';
import { KontaktComponent } from './kontakt/kontakt.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    ImpressumComponent,
    DataprotectComponent,
    DatenschutzComponent,
    ArchitekturComponent,
    ItsicherheitComponent,
    KontaktComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    NgbModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
